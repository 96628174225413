import Network from '@/services/network'
import { ref } from 'vue'

export default function handleInventory() {
    const network = new Network;
    const chartLoading = ref(false)
    const chartCreateLoading = ref(false)
    const reportLoading = ref(false)
    //-------------------------------------------------------------------------------

    const fetchHome = () => { //status, voucher, account_type
      return network.api('get', '/get/home');
    }
    const fetchClue = (companyId) => {
        return network.api('get', '/inventory/specifications?company_id='+companyId);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/account-heads?company_id='+companyId);
    }

    const fetchUnit = (companyId) => {
      return network.api('get', '/inventory/units?company_id='+companyId);
    }

    const fetchGroup = (companyId,productType) => {
      return network.api('get', 'inventory/products/groups?company_id='+companyId+'&is_flatten=1&product_type='+productType);
    }

    const storeSpecification = (data) => {
      return network.api('post', '/inventory/specifications', data);
    }

    const updateSpecification = (data) => {
      return network.api('post', '/inventory/specifications/'+data.id, data);
    }

    const fetchSpecification = (companyId) => {
      return network.api('get', '/inventory/specifications?company_id='+companyId);
    }

    const fetchProductList = (query) => {
      return network.api('get', '/inventory/products'+query);
    }

    const fetchSpecificationValue = (name, companyId) => {
      return network.api('get', `/inventory/specifications/${name}/values?company_id=`+companyId);
    }

    const storeProduct = (data) => {
      return network.api('post', '/inventory/products', data);
    }

    const storeGroup = (data) => {
      return network.api('post', '/inventory/products/groups', data);
    }

    const updateGroup = (data) => {
      return network.api('post', '/inventory/products/groups/'+data.id, data);
    }

    const updateProduct = (data, productId) => {
      return network.api('post', '/inventory/products/'+productId, data);
    }

    const getProduct = (data) => {
      return network.api('get', '/inventory/products/'+data.id+'?company_id='+data.company_id);
    }

    const fetchLocationList = (query) => {
        return network.api('get', '/inventory/business-locations' + query);
    }

    const fetchBusinessLocationGroup = (companyId, locationType) => {
        return network.api('get', 'inventory/business-locations/location-groups?company_id='
                                  + companyId
                                  + '&is_flatten=1&location_type='
                                  + locationType);
    }

    const storeBusinessLocationGroup = (data) => {
        return network.api('post', '/inventory/business-locations/location-groups', data);
    }

    const updateBusinessLocationGroup = (data) => {
        return network.api('post', '/inventory/business-locations/location-groups/' + data.id, data);
    }

    const storeBusinessLocation = (data) => {
        return network.api('post', '/inventory/business-locations', data);
    }

    const updateBusinessLocation = (data) => {
        return network.api('post', '/inventory/business-locations/update-location', data);
    }

    const getStockRegisterReport = (query) => {
        return network.api('get', '/inventory/stock-register'+ query);
    }

    const getStockLedgerReport = (query) => {
        return network.api('get', '/inventory/stock-ledger'+ query);
    }

    return {
        fetchHome,
        fetchClue,
        storeSpecification,
        fetchAccountHeads,
        fetchSpecification,
        fetchSpecificationValue,
        fetchUnit,
        fetchGroup,
        storeProduct,
        storeGroup,
        fetchProductList,
        updateGroup,
        updateProduct,
        getProduct,
        updateSpecification,

        fetchLocationList,
        fetchBusinessLocationGroup,
        storeBusinessLocationGroup,
        updateBusinessLocationGroup,
        storeBusinessLocation,
        updateBusinessLocation,

        getStockRegisterReport,
        getStockLedgerReport,

        chartCreateLoading,
        chartLoading,
        reportLoading
    }
}
